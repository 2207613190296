<template>
  <div>
    <domain-items-select
      id="product-select"
      label="Select Product"
      :items.sync="productsProxy"
      :category="category"
      :get-items-request="getItemsRequest"
    />
  </div>
</template>
<script>
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import DomainItemsSelect from '../domain-items/DomainItemsSelect.vue'

export default {
  name: 'ProductsSelect',
  components: { DomainItemsSelect },
  props: {
    products: { type: Array, default: () => [] },
    category: { type: String, default: 'business' },
  },
  computed: {
    productsProxy: {
      get() {
        return this.products
      },
      set(value) {
        this.$emit('update:products', value)
      },
    },
  },
  setup() {
    const { getItemsRequest } = domainItemApi('entities', '/internalops/product')
    return { getItemsRequest }
  },
}
</script>
<style lang="">

</style>
