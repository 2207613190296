<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <validation-provider
        #default="validationContext"
        name="Name"
        rules="required|min:3"
      >
        <b-form-group
          label="Tag"
          label-for="tag"
        >
          <b-form-input
            id="tag"
            v-model="tag.name"
            :state="getValidationState(validationContext)"
            autofocus
            placeholder="Tag Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <category-type-select
        v-if="!tagCategory"
        :category.sync="tag.category"
        rules="required"
      />

      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <submit-button
          :handle-submit="handleSubmit"
          :submit="addTag"
        />
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import CategoryTypeSelect from '../common/CategoryTypeSelect.vue'

export default {
  name: 'AddTag',
  components: {
    SubmitButton,
    CategoryTypeSelect,
  },
  props: {
    tagCategory: { type: String, default: '' },
  },
  data() {
    return {
      tag: {},
    }
  },
  setup() {
    // Form Validation
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert } = handleAlerts()

    return {
      getValidationState,
      successfulOperationAlert,

    }
  },
  created() {
    this.$set(this.tag, 'category', this.tagCategory)
  },
  methods: {
    addTag() {
      return this.$entities.post('/internalops/tag', {
        tag_name: this.tag.name,
        category: this.tag.category,
      }).then(() => {
        this.$bvModal.hide('add-modal')
        this.successfulOperationAlert('Tag is added successfully')
        this.$emit('refresh')
        this.$set(this.tag, 'category', this.tagCategory)
      })
    },
  },
}
</script>

<style>

</style>
