<template>
  <div>
    <domain-items-select
      id="services-select"
      label="Select services"
      :items.sync="servicesProxy"
      :category="category"
      :get-items-request="getItemsRequest"
    />
  </div>
</template>
<script>
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'
import DomainItemsSelect from '../domain-items/DomainItemsSelect.vue'

export default {
  name: 'ServicesSelect',
  components: { DomainItemsSelect },
  props: {
    services: { type: Array, default: () => [] },
    category: { type: String, default: 'business' },
  },
  computed: {
    servicesProxy: {
      get() {
        return this.services
      },
      set(value) {
        this.$emit('update:services', value)
      },
    },
  },
  setup() {
    const { getItemsRequest } = domainItemApi('entities', '/internalops/service')
    return { getItemsRequest }
  },
}
</script>
<style lang="">

</style>
