<template>
  <div>
    <custom-vue-select
      id="entity-select"
      :label="$attrs.label"
      placeholder="Select Entity"
      :rules="rules"
      :value-reducer="option=>option"
      :options="entities"
      :multiple="$attrs.multiple"
      :data-provider="getEntities"
      :selected.sync="entityProxy"
      text-field="name"
    />
  </div>
</template>

<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'EntitySelect',
  components: { CustomVueSelect },
  props: {
    entity: { type: [Array, Object], default: null },
    rules: { type: String, default: '' },
    filter: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      entities: [],
    }
  },
  computed: {
    entityProxy: {
      get() {
        return this.entity
      },
      set(value) {
        return this.$emit('update:entity', value)
      },
    },
  },
  created() {
    this.getEntities()
  },
  methods: {
    getEntities(query = null) {
      this.$entities.get('internalops/entity/list/', {
        params: {
          ...this.filter,
          search_str: query,
        },
      }).then(res => {
        this.entities = res.data.data.data
      })
    },
  },
}
</script>

<style>

</style>
