<template>
  <validation-observer
    v-slot="{ handleSubmit }"
  >
    <b-form
      class="p-2"
      @submit.enter.prevent
    >
      <!-- Title -->
      <text-input
        id="title"
        name="Title"
        rules="required"
        :text.sync="facility.name"
      />

      <!-- Description -->
      <text-area-input
        id="description"
        name="Description"
        rules="max:500"
        :text.sync="facility.description"
      />

      <category-type-select
        v-if="isCategoryEnabled"
        :category.sync="facility.category"
        rules="required"
      />

      <!-- Status -->
      <b-form-group
        v-if="isStatusEnabled"
        label="Status"
        label-for="status"
      >
        <b-checkbox
          id="status"
          v-model="facility.status"
          switch
        />
      </b-form-group>

      <!-- Form Actions -->
      <submit-button
        :handle-submit="handleSubmit"
        :submit="submit"
      />
    </b-form>
  </validation-observer>
</template>

<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import TextInput from '../../common/FormInputs/TextInput.vue'
import TextAreaInput from '../../common/FormInputs/TextAreaInput.vue'
import CategoryTypeSelect from '../common/CategoryTypeSelect.vue'

export default {
  name: 'FacilityForm',
  components: {
    SubmitButton,
    TextInput,
    TextAreaInput,
    CategoryTypeSelect,
  },
  props: {
    facility: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
    isStatusEnabled: { type: Boolean, default: true },
    isCategoryEnabled: { type: Boolean, default: true },
  },
}
</script>

<style>

</style>
